const useDwollaLayout = () => {
  const dwollaLayoutData = useState<boolean>('dwollaLayout', () => false);

  const setDwollaLayout = (value: boolean) => {
    dwollaLayoutData.value = value;
  };
  return {
    dwollaLayoutData,
    setDwollaLayout,
  };
};
export default useDwollaLayout;
